.list-small {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    width: 100%;
}

.card-small {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 250px;
    cursor: pointer;
}

.simple-card-small {
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding-bottom: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 250px;
}

.poster-small {
    width: 250px;
    border-radius: 10px;
}

.poster-simple-small {
    width: 250px;
    border-radius: 10px 10px 0 0;
}

.details-small {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}