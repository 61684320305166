@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------ COLOR PALETTE ------ */
@layer base {
  :root {
    --my-blue: #1777FF; /* blue-600 */
    --my-blue-hover: #1777FF; /* blue-700 */
    --my-red: #b61e26;
    --my-white1: #ffffff; /* white */
    --my-grayl2: #d1d5db; /* zinc-300 */

    --primary:var(--my-blue);
    --primary-hover:var(--my-blue-hover);
  }
}

/* ------ TEXT COLORS ------ */
@layer components {
  .tprimary {
    color: var(--primary);
  }
  .tprimary:hover {
    color: var(--primary-hover);
  }

  .tblue {
    color: var(--my-blue);
  }
  .tblue:hover {
    color: var(--my-blue-hover);
  }

  .tred {
    color: var(--my-red);
  }
}

/* ------ BACKGROUND COLORS ------ */
@layer components {
  .bprimary {
    background-color: var(--primary);
  }
  .bprimary:hover {
    background-color: var(--primary-hover);
  }

  .bblue {
    background-color: var(--my-blue);
  }
  .bblue:hover {
    background-color: var(--my-blue-hover);
  }

  .bred {
    background-color: var(--my-red);
  }
}

/* ------ BACKGROUND AND TEXT COMBOS COLORS ------ */
@layer components {
  .tbprimary {
    background-color: var(--primary);
    color: var(--my-white1);
  }
  .tbprimary:hover {
    background-color: var(--primary-hover);
  }

  .tbblue {
    background-color: var(--my-blue);
    color: var(--my-white1);
  }
  .tbblue:hover {
    background-color: var(--my-blue-hover);
  }

  .tbred {
    background-color: var(--my-red);
    color: var(--my-red);
  }
}

/* ------ OTHER CLASSES ------ */
@layer utilities {
  .dark {
    --primary: var(--my-red);
  }

  .flex-center {
    @apply flex justify-center items-center;
  }

  .flex-col-center {
    @apply flex flex-col justify-center;
  }

  .ring {
    --tw-ring-color: var(--primary);
  }

  .btn {
    @apply transition py-3 px-5 rounded uppercase font-bold text-xs shadow-gray-400 shadow focus:ring focus:ring-offset-2;
    background-color: var(--primary);
    color: var(--my-white1);
  }

  .btn:hover {
    background-color: var(--primary-hover);
  }

  .btn:focus {
    background-color: var(--primary-hover);
  }

  .btn-xl {
    @apply transition py-3 px-7 rounded uppercase font-bold text-base shadow-gray-400 shadow hover:ring focus:ring focus:ring-offset-2;
    background-color: var(--primary);
    color: var(--my-white1);
  }

  .btn-xl:hover {
    background-color: var(--primary-hover);
  }

  .btn-xl:focus {
    background-color: var(--primary-hover);
  }

  .btn-secondary {
    @apply border tprimary rounded leading-none px-6 py-2 cursor-pointer mx-2 hover:bprimary hover:twhite1 transition
  }

  .border-bottom-big {
    border-bottom: 6px solid var(--primary);
  }

  .border-bottom-medium {
    border-bottom: 3px solid var(--primary);
  }

  .border-bottom {
    border-bottom: 2px solid var(--primary);
  }

  .border-top {
    border-top: 4px solid var(--primary);
  }

  .border {
    border: 2px solid var(--primary);
  }

  .gradient-bg-left {
    background: linear-gradient(to bottom right, var(--my-white1), var(--my-grayl2));
  }

  .gradient-bg-right {
    background: linear-gradient(to bottom left, var(--my-grayl2), var(--my-white1));
  }

  .grayscale {
    filter: grayscale(25%);
  }

  .grayscale:hover {
    filter: grayscale(10%);
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  .infinite-scroll {
    animation: scroll 15s linear infinite;
  }

  .min-height-screen {
    min-height: calc(100vh - 68px);
  }


  .corner-cross-frame {
    position: relative;
    padding: 11px;
  }

  .corner-cross-frame::before,
  .corner-cross-frame::after {
    content: '';
    position: absolute;
    background-color: var(--my-blue);
  }

  .corner-cross-frame::before {
    top: 20px;
    left: 20px;
    width: 6px;
    height: 6px;
    box-shadow:
            0 10px 0 0 var(--my-blue),
            0 5px 0 0 var(--my-blue),
            5px 0 0 0 var(--my-blue),
            10px 0 0 0 var(--my-blue);
  }

  .corner-cross-frame::after {
    bottom: 20px;
    right: 20px;
    width: 6px;
    height: 6px;
    box-shadow:
            0 -10px 0 0 var(--my-blue),
            0 -5px 0 0 var(--my-blue),
            -5px 0 0 0 var(--my-blue),
            -10px 0 0 0 var(--my-blue);
  }

}

html {
  background: #141414;
}

body {
  @apply m-0 p-0 font-sans antialiased;
  font-family: "Nunito Sans", 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom right, var(--my-white1), var(--my-grayl2));
  min-height: 100vh;
}

code {
  font-family: 'Poppins', sans-serif;
}