.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1030;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 650px;
    min-width: 300px;
    max-height: 95vh;
    overflow-y: auto;
    background: white;
    border-radius: 8px;
    outline: none;
    padding: 20px;
    z-index: 1031;
}

@media (max-width: 768px) {
    .modal {
        width: 95%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .modal {
        width: 100%;
        min-width: unset;
        padding: 10px;
    }
}