.app {
  font-family: Arial, sans-serif;
  padding-left: 70px;
  padding-right: 70px;
}

/* For screens less than 768px wide (e.g., tablets and phones) */
@media screen and (max-width: 767px) {
  .app {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/* For screens less than 480px wide (e.g., smaller phones) */
@media screen and (max-width: 479px) {
  .app {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* For screens wider than 1200px (e.g., large desktops) */
@media screen and (min-width: 1200px) {
  .app {
    padding-left: 70px;
    padding-right: 70px;
  }
}


.overlay-gradient {
  position: relative;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.overlay-gradient::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}