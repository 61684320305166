.horizontal-scroll {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.scroll-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

.scroll-button {
    background-color: #d8d8d8;
    color: white;
    padding: 50px 5px;
    margin-bottom: auto;
    cursor: pointer;
    z-index: 10;
    border-radius: 10px;
    transform: translateY(115px);
}

.scroll-button.left {
    margin-right: 8px;
}

.scroll-button.right {
    margin-left: 8px;
}

.scroll-button:hover {
    background-color: #bcbcbc;
}

.scroll-container > * {
    flex: 0 0 auto;
    margin-right: 25px;
}
